define('ember-cli-matomo/mixins/page-view-tracker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    /**
     * Calls the Piwik tracker to send a pageview.
     *
     * Listens to the `didTransition` event.
     *
     * @member {Function} trackPiwikPageView
     * @since 0.0.2
     */
    trackPiwikPageView: Ember.on('didTransition', function () {
      _paq.push(['trackPageView', this.get('url')]);
    })
  });
});