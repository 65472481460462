define('ember-models-table/-private/column', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer,
      computed = Ember.computed,
      isEmpty = Ember.isEmpty,
      O = Ember.Object,
      set = Ember.set,
      get = Ember.get,
      A = Ember.A;
  exports.default = O.extend({

    cssPropertyName: computed('propertyName', function () {
      return get(this, 'propertyName').replace(/\./g, '-');
    }),

    isVisible: computed.not('isHidden'),

    sortAsc: computed.equal('sorting', 'asc'),

    sortDesc: computed.equal('sorting', 'desc'),

    filterUsed: computed.notEmpty('filterString'),

    /**
     * If preselected option doesn't exist after <code>filterOptions</code> update,
     * <code>filterString</code> is reverted to empty string (basically, filtering for this column is dropped)
     */
    cleanFilterString: observer('filterWithSelect', 'filterOptions.[]', 'filterString', function () {
      var filterOptions = get(this, 'filterOptions');
      var filterWithSelect = get(this, 'filterWithSelect');
      var filterString = get(this, 'filterString');
      if (!filterWithSelect || isEmpty(filterOptions)) {
        return;
      }
      if (!A(filterOptions).findBy('value', filterString)) {
        set(this, 'filterString', '');
      }
    })

  });
});